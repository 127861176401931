<template>
  <div class="notification">
    <div class="text-center">
      <v-snackbar content-class="snack" :timeout="-1" :value="show">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="$emit('close')">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", "text"],
  data() {
    return {
      snackbar: false,
      timeout: 3500,
    };
  },
  watch: {
    show: function () {
      if (this.show) {
        setTimeout(() => {
          this.$emit("close");
        }, this.timeout);
      }
    },
  },
};
</script>


<style lang="scss">
.notification {
  padding: 0;
}
.text-center {
  color: white;
}
.snack {
  max-width: 343px !important;
}
</style>